import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  margin-top: 0;
  padding: 20px 40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: 450px) {
    img {
      width: 40%;
      height: 40%;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #ebf1ed;
  padding: 10px;
  border-radius: 8px;
  img {
    align-self: center;
  }
  ul {
    margin: 10px, 0;
    text-align: center;
    list-style: none;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 22px;
    font-size: 22px;
    text-align: center;
  }
  h2,
  h3,
  h4 {
    margin-top: 12px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 90%;
    gap: 10px;

    .mantine-Group-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }

    .mantine-RadioGroup-label {
      font-size: 18px;
      font-weight: 600;
    }
    .mantine-Radio-labelWrapper {
      font-size: 18px;
    }
  }

  @media (max-width: 680px), (max-width: 450px) {
    strong {
      font-size: 14px;
    }

    h1 {
      margin: 0;

      font-size: 14px;
    }

    p {
      line-height: 10px;
      font-size: 12px;
      padding: 0 5px;
      text-align: justify;
    }
  }
`;
