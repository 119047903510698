import styled from 'styled-components';

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 10px;
    text-align: center;
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: center;
  }
  select {
    margin-bottom: 5px;
  }
  p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0 40px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  a {
    text-decoration: none;
  }
  label {
    margin: 20px 0;
    text-align: center;
    input {
      margin-right: 10px;
    }
  }

  @media (max-width: 720px) {
    select {
      font-size: 12px;
      width: 300px;
    }
  }

  @media (max-width: 680px), (max-width: 450px) {
    h1 {
      margin: 0;
      font-size: 14px;
    }
    p {
      line-height: 15px;
      font-size: 12px;
      padding: 0 5px;
      text-align: justify;
    }
    label {
      margin: 10px 0;
      input {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 350px) {
    iframe {
      width: 265px;
      height: 165px;
    }
  }
`;

export const Documents = styled.div`
  margin-top: 10px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 680px), (max-width: 450px) {
    margin-top: 5px;
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    div {
      margin-bottom: 5px;
    }
  }
`;

export const Banner = styled.div`
  @media (max-width: 720px) {
    width: 612px;
    height: 344.25px;
  }

  @media (max-width: 620px) {
    width: 480px;
    height: 270px;
  }
  @media (max-width: 430px) {
    width: 360px;
    height: 202.5px;
  }
  @media (max-width: 395px) {
    width: 300px;
    height: 168.75px;
  }
  @media (max-width: 320px) {
    width: 240px;
    height: 135px;
  }
  width: 640px;
  height: 360px;

  iframe {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
