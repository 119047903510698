import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { AiOutlineFilePdf, AiOutlineArrowRight } from 'react-icons/ai';

import { Button, Text, Title } from '@mantine/core';
import Template from '../Template';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import { Information, Documents, Banner } from './styles';

interface AssembleiaData {
  recebe_votos: boolean;
  id: string;
  titulo: string;
  descricao: string;
  link_edital: string;
  link_proposta: string;
  link_youtube: string;
  link_banner: string;
  data_inicio: Date;
  data_fim: Date;
  ativa: boolean;
}

function Assembleia() {
  const [checked, setChecked] = useState(false);
  const [assembleias, setAssembleias] = useState<AssembleiaData[]>([]);
  const [assembleia, setAssembleia] = useState<AssembleiaData>(
    {} as AssembleiaData,
  );
  const [assembleiaId, setAssembleiaId] = useState('');
  const [loadAssembleia, setLoadAssembleia] = useState(false);

  const history = useHistory();

  const { addToast } = useToast();

  const handlePopulateSelect = useCallback(async () => {
    const response = await api.get('assembleias?type=select');

    let assembleiasData: Array<AssembleiaData> = [] as Array<AssembleiaData>;

    response.data.forEach((assembleiaData: AssembleiaData): void => {
      assembleiasData = [...assembleiasData, assembleiaData];
    });

    setLoadAssembleia(assembleiasData.length > 0);
    setAssembleias(assembleiasData);
    setAssembleia(assembleiasData[0]);
  }, []);

  useEffect(() => {
    handlePopulateSelect();
  }, [handlePopulateSelect]);

  const handleToValidate = useCallback(() => {
    if (!checked) {
      addToast({
        title: 'Confirme a opção',
        description:
          process.env.REACT_APP_MSG_ERRO_ACEITE ||
          'Você precisa marcar que assistiu vídeo e leu o edital e a proposta para votar!',
        type: 'error',
      });
      return;
    }
    sessionStorage.setItem(
      '@Suffragium:assembleia',
      JSON.stringify(assembleia),
    );
    history.push('/assembleia/validation');
  }, [history, checked, addToast, assembleia]);

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      const { value } = event.target;
      if (value) {
        const assembleiaSelection = assembleias.find(
          (assembleiaObject: AssembleiaData) => assembleiaObject.id === value,
        );

        if (assembleiaSelection) {
          setAssembleia(assembleiaSelection);
          setAssembleiaId(value);
          setLoadAssembleia(true);
        }
      }
    },
    [assembleias],
  );

  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
    },
    [setChecked],
  );

  return (
    <Template>
      <Information>
        {assembleias.length > 1 && (
          <>
            <h2>ASSEMBLEIAS COM VOTAÇÃO EM ABERTO</h2>
            <select
              name="assembleiasList"
              id="assembleiasList"
              value={assembleiaId}
              onChange={handleSelectChange}
            >
              {assembleias.map((option, index) => {
                return (
                  <option
                    value={option.id}
                    key={option.id}
                    selected={index === 0}
                  >
                    {option.titulo}
                  </option>
                );
              })}
            </select>
          </>
        )}
        {loadAssembleia && (
          <>
            <Title>{assembleia.titulo}</Title>
            <Text pt="md" ta="center">
              {assembleia.descricao}
            </Text>

            <Banner>
              {!!assembleia.link_youtube && (
                <iframe
                  title="Vídeo Assembleia"
                  src={assembleia.link_youtube}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
              {!assembleia.link_youtube && !!assembleia.link_banner && (
                <img src={assembleia.link_banner} alt="Banner Assembleia" />
              )}
            </Banner>

            <Documents>
              <Button
                component="a"
                href={assembleia.link_edital}
                rightIcon={<AiOutlineFilePdf />}
                size="lg"
              >
                {process.env.REACT_APP_BTN_LINK_EDITAL || 'LER EDITAL'}
              </Button>
              {!!assembleia.link_proposta && (
                <Button
                  component="a"
                  href={assembleia.link_proposta}
                  rightIcon={<AiOutlineFilePdf />}
                  size="lg"
                >
                  {process.env.REACT_APP_BTN_LINK_PROPOSTA || 'LER PROPOSTA'}
                </Button>
              )}
            </Documents>
            {!!assembleia.recebe_votos && (
              <>
                <label htmlFor="termo">
                  <input
                    type="checkbox"
                    name="termo"
                    id="termo"
                    checked={checked}
                    onChange={handleCheck}
                  />
                  <strong>
                    {process.env.REACT_APP_MSG_ACEITE ||
                      'Assisti o vídeo, li o edital e a proposta.'}
                  </strong>
                </label>
                <Button
                  onClick={handleToValidate}
                  rightIcon={<AiOutlineArrowRight />}
                  size="lg"
                  color="green"
                >
                  ACESSAR VOTAÇÃO
                </Button>
              </>
            )}
          </>
        )}
        {assembleias.length === 0 && (
          <>
            <h1>Bem vindo a área de assembleias!</h1>
            <h3>No momento não existem assembleias ativas.</h3>
          </>
        )}
      </Information>
    </Template>
  );
}

export default Assembleia;
